@media (max-width: 540px) {
  html {
    font-size: 8px; } }

@media (min-width: 540px) {
  html {
    font-size: 10px; } }

@media (min-width: 767px) {
  html {
    font-size: 14px; } }

@media (min-width: 992px) {
  html {
    font-size: 16px; } }

html, body {
  margin: 0;
  padding: 0;
  height: 100%; }

#page-wrapper {
  min-height: 100%;
  position: relative; }

#main-wrapper {
  padding-bottom: 340px; }

/*
.page-harmonia-for-teachers,
.page-front,
.page-harmonia-app,
.page-online-lessons{
  #main-wrapper {
    padding-bottom: $footer-height !important;

  }
}
*/
#footer .footer-disclaimer {
  color: #1D2731; }
  #footer .footer-disclaimer p {
    color: #1D2731; }

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 300px;
  background-color: #f0f1f4;
  color: #1D2731;
  margin-top: 40px; }
  #footer a:link {
    color: #d99510;
    text-decoration: none; }
  #footer a:visited {
    color: #cc4d00;
    text-decoration: none; }
  #footer a:hover {
    color: #D9B310;
    text-decoration: none; }
  #footer #harmonia-footer {
    padding-top: 50px; }
    #footer #harmonia-footer a.highlight {
      font-weight: bold;
      text-decoration: underline; }

.vimeo-wrapper {
  position: relative;
  padding-bottom: 33.75%;
  /* 16:9 ratio*/
  padding-top: 25px;
  margin: 0 20%; }
  @media (max-width: 767px) {
    .vimeo-wrapper {
      padding-bottom: 56.25%;
      margin: 0; } }

.vimeo-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

#admin-menu {
  font-size: 16px;
  z-index: 100; }

table#dashboard-student-assignment-status-table {
  background-color: #f0f1f4; }

table tbody {
  background-color: #f0f1f4; }

table tr {
  color: #1D2731; }

table th, table td {
  color: #1D2731;
  padding-left: 5px; }

select {
  color: #1D2731; }

.views-table td {
  padding: 7px;
  color: #1D2731; }

.views-table th {
  color: #f0f1f4; }
  .views-table th.active img {
    margin-left: 5px; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 100; }
  h1.normal-weight-header, h2.normal-weight-header, h3.normal-weight-header, h4.normal-weight-header, h5.normal-weight-header, h6.normal-weight-header {
    font-weight: 100; }

.normal-weight {
  font-weight: 100; }
  .normal-weight h1, .normal-weight h2, .normal-weight h3, .normal-weight h4, .normal-weight h5, .normal-weight h6 {
    font-weight: 100; }

body, p {
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  color: #f0f1f4;
  transition-duration: 0.2s; }

h1 {
  font-size: 2.5em;
  color: #f0f1f4;
  border-bottom: 1px solid #f0f1f4;
  margin-bottom: 30px; }

h1#page-title {
  display: none; }

h2 {
  font-size: 1.68em;
  color: #f0f1f4; }

h3 {
  font-size: 1.41em;
  color: #f0f1f4; }

h4 {
  font-size: 1.19em;
  color: #f0f1f4; }

h5 {
  font-size: 1.05em;
  color: #f0f1f4; }

a {
  text-decoration: none; }

a:link {
  color: #D9B310;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05); }
  a:link.darker {
    color: #d99510; }

a:visited {
  color: #d99510;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05); }
  a:visited.darker {
    color: #cc4d00; }

a:hover, .carousel-control:hover {
  color: #efd35e;
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05); }
  a:hover.darker, .carousel-control:hover.darker {
    color: #D9B310; }

ul, li, li a {
  font-family: 'Lato', sans-serif; }

input[type='text'] {
  max-width: 100%;
  background-color: #f0f1f4;
  color: #1D2731;
  padding-left: 4px; }

input[type='submit'], button, a.button {
  white-space: normal;
  font-family: 'Lato', sans-serif;
  border: 0px;
  background-color: #efd35e;
  margin: 10px;
  font-size: 1.1em;
  color: #1D2731;
  padding: 5px 20px;
  transition-duration: 0.2s; }
  input[type='submit'].no-left-margin, button.no-left-margin, a.button.no-left-margin {
    margin-left: 0px; }
  input[type='submit'] a, input[type='submit'] a:link, button a, button a:link, a.button a, a.button a:link {
    color: #1D2731; }
    input[type='submit'] a:visited, input[type='submit'] a:link:visited, button a:visited, button a:link:visited, a.button a:visited, a.button a:link:visited {
      color: #1D2731; }
  input[type='submit']:hover, button:hover, a.button:hover {
    background-color: #D9B310;
    color: #f0f1f4; }
    input[type='submit']:hover a, button:hover a, a.button:hover a {
      color: #f0f1f4; }
  @media (max-width: 767px) {
    input[type='submit'], button, a.button {
      padding: 5px 10px; } }
  @media (max-width: 540px) {
    input[type='submit'], button, a.button {
      padding: 5px 10px; } }

input[type='submit']#edit-cancel, button.cancel-new-extension-button, button#content-library-cancel-add {
  background-color: #871b1b;
  color: #f0f1f4; }
  input[type='submit']#edit-cancel:hover, button.cancel-new-extension-button:hover, button#content-library-cancel-add:hover {
    background-color: #1D2731;
    color: red; }

.zone, .zone-wrapper {
  clear: both; }

.zone {
  max-width: 1280px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: relative; }

html.not-front #main {
  background-color: #1D2731;
  min-height: 600px; }

#main-wrapper {
  padding-top: 45px;
  background-color: #1D2731; }
  .page-user-login #main-wrapper, .page-user-register #main-wrapper {
    background-color: white; }
  #main-wrapper div.content {
    padding: 0px 30px 30px 30px; }

body.page-node-193 #main-wrapper, body.page-node-194 #main-wrapper, body.page-node-309 #main-wrapper, body.page-complete-content-library #main-wrapper {
  background-color: #54606d; }

div.messages {
  margin: 0; }
  @media (min-width: 767px) {
    div.messages {
      padding-top: 40px;
      background-position: 10px 40px; } }

body.page-blog #main-wrapper, body.node-type-blog-article #main-wrapper {
  background-color: #54606d; }
  body.page-blog #main-wrapper #content, body.node-type-blog-article #main-wrapper #content {
    margin: 0px 15px 0px 15px; }

body.page-blog body, body.page-blog p, body.node-type-blog-article body, body.node-type-blog-article p {
  font-weight: 100; }

body.page-user-edit input[type='text'], body.page-user-edit input[type='password'] {
  display: block;
  width: 100%;
  max-width: 500px;
  border: 1px solid #777;
  padding: 2px 5px; }

body.page-user-edit select {
  display: block;
  width: 100%;
  max-width: 500px; }

body.page-user-edit input[type='radio'] {
  margin-top: -4px; }

body.page-user-edit a {
  color: #3b698d; }

body.page-user-edit h1 {
  margin: 20px 30px 20px 30px; }

body.page-user-edit h3 {
  margin-top: 0; }

body.page-user-edit .form-item-current-pass, body.page-user-edit .form-item-mail, body.page-user-edit .form-item-pass, body.page-user-edit #full-name-wrapper,
body.page-user-edit .form-item-timezone, body.page-user-edit #edit-locale, body.page-user-edit #profile-form-account-type {
  border: 1px solid #3b698d;
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  padding: 20px;
  margin-bottom: 30px;
  background-color: #e1e3e9; }
  body.page-user-edit .form-item-current-pass h3, body.page-user-edit .form-item-mail h3, body.page-user-edit .form-item-pass h3, body.page-user-edit #full-name-wrapper h3,
  body.page-user-edit .form-item-timezone h3, body.page-user-edit #edit-locale h3, body.page-user-edit #profile-form-account-type h3 {
    color: #1D2731; }

body.page-user-edit .password-parent, body.page-user-edit .confirm-parent {
  width: inherit; }

body.page-user-edit .password-strength {
  float: none;
  margin-top: 0;
  margin-bottom: 20px; }

body.page-user-edit .password-suggestions {
  width: 100% !important;
  max-width: 500px !important; }

#my-profile {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  padding: 20px;
  position: relative;
  background-color: #e1e3e9; }
  #my-profile button {
    position: absolute;
    top: 0;
    right: 0; }
  #my-profile h2 {
    margin-bottom: 30px;
    color: #1D2731; }
  #my-profile .my-profile-field {
    border-bottom: 1px solid #874C00;
    margin-bottom: 30px; }
  #my-profile i {
    margin-right: 10px;
    font-weight: 1.2em;
    color: #3b698d; }

#dashboard-courses-as-instructor .course-item,
#dashboard-courses-as-ta .course-item,
#dashboard-courses-as-student .course-item {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #0A3858;
  padding: 0 40px 20px 80px;
  background-color: #e1e3e9;
  color: #1D2731;
  margin-bottom: 15px; }
  #dashboard-courses-as-instructor .course-item:before,
  #dashboard-courses-as-ta .course-item:before,
  #dashboard-courses-as-student .course-item:before {
    content: "\f02d";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    left: 25px;
    top: 16px;
    color: #328CC1;
    font-size: 2em; }
  #dashboard-courses-as-instructor .course-item .dashboard-pending-requests-tag span,
  #dashboard-courses-as-ta .course-item .dashboard-pending-requests-tag span,
  #dashboard-courses-as-student .course-item .dashboard-pending-requests-tag span {
    background-color: #0A3858;
    color: #f0f1f4;
    padding: 3px 8px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #f0f1f4; }
    #dashboard-courses-as-instructor .course-item .dashboard-pending-requests-tag span a,
    #dashboard-courses-as-ta .course-item .dashboard-pending-requests-tag span a,
    #dashboard-courses-as-student .course-item .dashboard-pending-requests-tag span a {
      color: #f0f1f4; }

/* dashboard main content */
#dashboard-instructor-step-by-step-guide,
#harmonia-user-notification-container,
#harmonia-invitations-container,
#dashboard-student-step-by-step-guide {
  min-height: 130px;
  margin: 0 30px 20px 30px;
  color: #1D2731;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: #b9d9ed;
  position: relative;
  border: 1px solid #777;
  padding: 20px 20px 20px 60px;
  font-size: 1.3em;
  overflow: hidden; }
  #dashboard-instructor-step-by-step-guide .guide-content,
  #dashboard-instructor-step-by-step-guide #harmonia-invitations,
  #harmonia-user-notification-container .guide-content,
  #harmonia-user-notification-container #harmonia-invitations,
  #harmonia-invitations-container .guide-content,
  #harmonia-invitations-container #harmonia-invitations,
  #dashboard-student-step-by-step-guide .guide-content,
  #dashboard-student-step-by-step-guide #harmonia-invitations {
    z-index: 3;
    position: relative; }
    #dashboard-instructor-step-by-step-guide .guide-content p,
    #dashboard-instructor-step-by-step-guide #harmonia-invitations p,
    #harmonia-user-notification-container .guide-content p,
    #harmonia-user-notification-container #harmonia-invitations p,
    #harmonia-invitations-container .guide-content p,
    #harmonia-invitations-container #harmonia-invitations p,
    #dashboard-student-step-by-step-guide .guide-content p,
    #dashboard-student-step-by-step-guide #harmonia-invitations p {
      font-size: 1.35em; }
  #dashboard-instructor-step-by-step-guide:after,
  #harmonia-user-notification-container:after,
  #harmonia-invitations-container:after,
  #dashboard-student-step-by-step-guide:after {
    content: "\f05a";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    left: 17px;
    top: -10px;
    color: white;
    font-size: 5em;
    opacity: 0.4; }

#dashboard-instructor-step-by-step-guide,
#dashboard-student-step-by-step-guide {
  margin: 0 0 20px 0; }

#harmonia-invitations-container {
  background-color: #b9d9ed;
  margin: 0 0 20px 0; }

#harmonia-notification {
  background-color: #1D2731; }
  @media (min-width: 767px) {
    #harmonia-notification {
      padding-top: 40px; } }

#harmonia-notification,
#harmonia-invitations {
  margin-top: 0px;
  font-size: 0.8em; }
  #harmonia-notification .harmonia-user-notification-item,
  #harmonia-notification .invitation,
  #harmonia-invitations .harmonia-user-notification-item,
  #harmonia-invitations .invitation {
    border-bottom: 1px solid #527b35;
    margin-top: 10px;
    margin-bottom: 25px;
    position: relative; }
  #harmonia-notification .fa,
  #harmonia-invitations .fa {
    position: absolute;
    top: 6px; }
  #harmonia-notification .harmonia-user-notification-message,
  #harmonia-invitations .harmonia-user-notification-message {
    margin-left: 30px; }
  #harmonia-notification .harmonia-user-notification-dismiss,
  #harmonia-invitations .harmonia-user-notification-dismiss {
    padding: 1px 10px;
    font-size: 12px;
    cursor: pointer; }
  #harmonia-notification h1, #harmonia-notification h2, #harmonia-notification h3, #harmonia-notification h4, #harmonia-notification h5, #harmonia-notification h6,
  #harmonia-invitations h1,
  #harmonia-invitations h2,
  #harmonia-invitations h3,
  #harmonia-invitations h4,
  #harmonia-invitations h5,
  #harmonia-invitations h6 {
    color: #1D2731; }

/* access denied login page */
body.page-access-denied #main-wrapper div.content {
  text-align: center;
  padding-top: 30px; }

body.page-access-denied #user-login input {
  color: #1D2731; }

/* login page */
body.page-user-login form input.error {
  border: 2px inset; }

body.page-user-login h2.sign-in-title {
  color: #1D2731;
  font-weight: normal;
  letter-spacing: 0.3em; }
  @media (min-width: 767px) {
    body.page-user-login h2.sign-in-title {
      letter-spacing: 0.5em; } }

body.page-user-login #main {
  min-height: 300px; }

/* register page */
body.page-user-register h2.sign-up-title {
  color: #1D2731;
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.3em; }
  @media (min-width: 767px) {
    body.page-user-register h2.sign-up-title {
      letter-spacing: 0.5em; } }

body.page-user-register #main {
  min-height: 300px; }

body.page-user-register .form-actions.form-wrapper {
  text-align: center; }

body.page-user-register .description.mollom-privacy {
  display: none; }

#dashboard-courses-as-student {
  margin-bottom: 100px;
  overflow: hidden; }

/* Individual dashboard */
#dashboard-individual-courses {
  margin-top: 40px; }
  #dashboard-individual-courses h1 {
    color: #2E3ED1;
    text-align: center;
    border-bottom: 0;
    transform: scale(1, 1.5);
    font-weight: 100; }
  #dashboard-individual-courses .individual-course {
    box-shadow: 2px 2px 10px 1px #666;
    margin-left: 5px;
    margin-right: 5px;
    padding: 20px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    background-color: #b9bbbb;
    margin-top: 20px;
    margin-bottom: 50px;
    position: relative; }
    #dashboard-individual-courses .individual-course img {
      max-width: 100%;
      height: auto; }
    #dashboard-individual-courses .individual-course h2 {
      color: #F8F7F2; }
    #dashboard-individual-courses .individual-course .course-overview-summary {
      color: #F8F7F2;
      font-weight: 100; }
  #dashboard-individual-courses .learn-more {
    margin-top: 30px;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-weight: bold; }
    #dashboard-individual-courses .learn-more a {
      color: #2E3ED1; }

.white {
  color: #f0f1f4; }

.row.is-flex {
  display: flex;
  flex-wrap: wrap; }

.row.is-flex > [class*='col-'] {
  display: flex;
  flex-direction: column; }

.fancy-card {
  overflow: auto; }
  .fancy-card p {
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    text-align: center; }

.fancy-card-image {
  width: 100%;
  max-width: 120px;
  display: block;
  margin: 0 auto; }

a.fancy-button {
  color: #efd35e; }
  a.fancy-button:hover {
    color: #fff; }

.fancy-button-block {
  font-family: 'Cardo', serif;
  display: block;
  font-size: 0.9em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 5px;
  border: 3px solid #e1e3e9;
  padding: 25px;
  margin-bottom: 10px;
  /*  centering */
  position: relative;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background 0.15s ease-in-out; }
  .fancy-button-block:hover {
    background-color: rgba(8, 97, 76, 0.6); }
  @media (max-width: 767px) {
    .fancy-button-block {
      margin-bottom: 5px; } }

.fancy-button {
  font-family: 'Cardo', serif;
  display: inline-block;
  font-size: 0.9em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 5px;
  border: 3px solid #f0f1f4;
  padding: 25px;
  margin-bottom: 20px;
  /*  centering */
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  top: 50%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background 0.15s ease-in-out; }
  .fancy-button:hover {
    background: rgba(8, 97, 76, 0.6); }
  .fancy-button.download-tab {
    max-width: 400px; }

.fancy-button-wrapper.content-samples .fancy-button-block {
  background-color: #1D2731;
  opacity: 0.7;
  color: #f0f1f4; }
  .fancy-button-wrapper.content-samples .fancy-button-block:hover {
    background-color: #1D2731;
    color: #D9B310; }

i.fancy-shine {
  /*   positioning */
  position: absolute;
  opacity: 0.2;
  top: 0;
  left: 35%;
  /*   gradient   */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  /*  forming the shine element
    play around with the width, skew and gradient to get different effects
    */
  width: 15%;
  height: 100%;
  /*  animating it  */
  animation-timing-function: linear;
  animation: move 2s;
  animation-iteration-count: 1;
  animation-delay: 1s;
  transform: skew(-30deg, 0deg) translateZ(0); }

.fancy-button-block:hover i.fancy-shine, .fancy-button:hover i.fancy-shine {
  animation: move 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s; }

/*  */
@keyframes move {
  0% {
    left: 5%;
    opacity: 0.0; }
  50% {
    opacity: 0.2; }
  100% {
    left: 85%;
    opacity: 0.0; } }

.company-about-us .image-wrapper {
  text-align: center;
  margin-top: 30px; }
  @media (max-width: 767px) {
    .company-about-us .image-wrapper {
      margin-top: 80px; } }

.company-about-us .image {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
  overflow: hidden;
  border: 5px solid #fff;
  width: 150px;
  height: 150px;
  -webkit-box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
  display: inline-block; }

.company-about-us img.headshot {
  height: 150px;
  width: 150px; }

.company-about-us .employees {
  padding-top: 10px; }

.company-about-us .full-name {
  color: #f0f1f4;
  text-align: center;
  font-size: 1.3em; }

.company-about-us .job-title {
  color: #f0f1f4;
  text-align: center;
  font-size: 1.1em;
  margin-bottom: 25px; }
  @media (max-width: 767px) {
    .company-about-us .job-title {
      margin-bottom: 10px; } }

.company-about-us .short-bio {
  text-align: center; }
  .company-about-us .short-bio p {
    font-size: 0.9em; }
    @media (max-width: 992px) {
      .company-about-us .short-bio p {
        font-size: 1em; } }

.illiac-logo img {
  max-width: 100%; }

.about-illiac p {
  color: #f0f1f4; }

.card-container {
  box-shadow: 1px -1px 16px -3px rgba(255, 255, 255, 0.75); }
  .card-container h1, .card-container h2, .card-container h3 {
    color: #1D2731; }
  .card-container p {
    color: #0A3858; }
  .card-container .image {
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    border: 5px solid #f0f1f4;
    width: 150px;
    height: 150px;
    box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
    display: inline-block; }
  .card-container img.headshot {
    height: 150px;
    width: 150px; }

/* entire container, keeps perspective */
.card-container {
  perspective: 800px;
  margin-bottom: 30px;
  cursor: pointer; }

/* flip the pane when hovered */
.card-container:not(.manual-flip):hover .card,
.card-container.hover.manual-flip .card {
  transform: rotateY(180deg); }

.card-container.static:hover .card,
.card-container.static.hover .card {
  transform: none; }

/* flip speed goes here */
.card {
  transition: transform .5s;
  transform-style: preserve-3d;
  position: relative; }

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f0f1f4;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14); }

/* front pane, placed above back */
.front {
  z-index: 2; }

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
  z-index: 3; }

.back .btn-simple {
  position: absolute;
  left: 0;
  bottom: 4px; }

/*        Style       */
.card {
  background: none repeat scroll 0 0 #f0f1f4;
  border-radius: 4px;
  color: #444444; }

.card-container, .front, .back {
  width: 100%;
  height: 395px;
  border-radius: 4px;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.16); }

.card .cover {
  height: 155px;
  overflow: hidden;
  border-radius: 4px 4px 0 0; }

.card .cover img {
  width: 100%; }

.card .user {
  text-align: center;
  border-radius: 50%;
  display: block;
  margin: -105px auto 0; }

.card .card-content {
  background-color: transparent;
  box-shadow: none;
  padding: 0px 20px 20px; }

.card .card-content .main {
  min-height: 100px; }

.card .back .card-content .main {
  height: 215px; }

.card .name {
  font-family: 'Arima Madurai', cursive;
  font-size: 22px;
  line-height: 28px;
  margin: 10px 0 0;
  text-align: center;
  text-transform: capitalize; }

.card h5 {
  margin: 5px 0;
  font-weight: 400;
  line-height: 20px; }

.card .profession {
  text-align: center;
  margin-bottom: 20px; }

.card .footer {
  border-top: 1px solid #EEEEEE;
  color: #999999;
  padding: 10px 0 0;
  text-align: center; }

.card .footer .social-links {
  font-size: 18px; }

.card .footer .social-links a {
  margin: 0 7px; }

.card .footer .btn-simple {
  margin-top: -6px; }

.card .header {
  padding: 15px 20px;
  height: 90px; }

.card .motto {
  font-family: 'Arima Madurai', cursive;
  border-bottom: 1px solid #EEEEEE;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center; }

.card .stats-container {
  width: 100%;
  margin-top: 50px; }

.card .stats {
  display: block;
  float: left;
  width: 33.333333%;
  text-align: center; }

.card .stats:first-child {
  border-right: 1px solid #EEEEEE; }

.card .stats:last-child {
  border-left: 1px solid #EEEEEE; }

.card .stats h4 {
  font-family: 'Arima Madurai', cursive;
  font-weight: 300;
  margin-bottom: 5px; }

.card .stats p {
  color: #777777; }

.btn-simple {
  opacity: .8;
  color: #666666;
  background-color: transparent; }

.btn-simple:hover,
.btn-simple:focus {
  background-color: transparent;
  box-shadow: none;
  opacity: 1; }

.btn-simple i {
  font-size: 16px; }

/*       Fix bug for IE      */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .front, .back {
    -ms-backface-visibility: visible;
    backface-visibility: visible; }
  .back {
    visibility: hidden;
    -ms-transition: all 0.2s cubic-bezier(0.92, 0.01, 0.83, 0.67); }
  .front {
    z-index: 4; }
  .card-container:not(.manual-flip):hover .back,
  .card-container.manual-flip.hover .back {
    z-index: 5;
    visibility: visible; } }

/** Promo Sticker TEMPORARY **/
.promo-sticker {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 400px;
  max-width: 60%;
  transition: opacity 0.4s;
  opacity: 1; }

input.promo-sticker-click-hide, :checked + .promo-sticker {
  opacity: 0; }

/** Header **/
#harmonia-static-blocks-page-top-toolbar .navbar-top {
  padding: 10px 0px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100; }

.logo-image {
  position: relative; }

#header {
  box-shadow: inset 0 0 3px rgba(29, 39, 49, 0.4);
  background-color: #f0f1f4; }

.navbar-header {
  position: relative;
  overflow-x: hidden; }

.page-top-navbar {
  border: 0;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 16px; }
  @media (max-width: 992px) {
    .page-top-navbar {
      font-size: 14px; } }
  @media (max-width: 870px) {
    .page-top-navbar {
      font-size: 12px; } }

.navbar-toggle {
  z-index: 1; }

.page-top-navbar .navbar-nav > li > a, .page-top-navbar .nav-user .item a {
  color: #0A3858; }
  .page-top-navbar .navbar-nav > li > a.current, .page-top-navbar .nav-user .item a.current {
    color: #d99510;
    font-weight: bold; }

.page-top-navbar .navbar-nav > .active > a,
.page-top-navbar .navbar-nav > .active > a:hover,
.page-top-navbar .navbar-nav > .active > a:focus,
.page-top-navbar .navbar-nav > li > a:hover,
.page-top-navbar .navbar-nav > li > a:focus,
.nav-user .item a:hover {
  background-color: transparent;
  border-radius: 3px;
  color: #D9B310;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-collapse, .nav-user {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  text-transform: uppercase; }

@media (min-width: 768px) {
  .navbar-collapse {
    position: absolute;
    background-color: #f0f1f4;
    opacity: 0.7;
    bottom: -50%;
    right: 30px;
    box-shadow: 0 0 7px rgba(29, 39, 49, 0.4);
    padding-top: 5px; }
  .page-top-navbar .navbar-nav > li > a {
    padding: 10px 15px 10px 0px; }
  .nav-user {
    text-align: right;
    padding-bottom: 20px; }
    .nav-user .item {
      display: inline;
      padding-left: 20px; }
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px; }
  .navbar-nav > li > .dropdown-menu, .dropdown-menu {
    display: block;
    margin: 0;
    padding: 0;
    z-index: 9000;
    position: absolute;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    box-sizing: border-box;
    opacity: 0;
    filter: alpha(opacity=0);
    transform: scale(0);
    transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1); }
  .navbar-nav > li.open > .dropdown-menu, .open .dropdown-menu {
    transform-origin: 29px -50px;
    transform: scale(1);
    opacity: 1;
    filter: none;
    padding: 5px 10px 15px; }
  .dropdown .dropdown-menu {
    top: -30px; }
  .fixed-plugin .dropdown-menu:before,
  .fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50px;
    width: 16px;
    transform: translateY(-50%); }
  .fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid transparent;
    border-left: 16px solid rgba(0, 0, 0, 0.2);
    border-top: 16px solid transparent;
    right: -16px; }
  .fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid transparent;
    border-left: 16px solid #FFFFFF;
    border-top: 16px solid transparent;
    right: -15px; } }

@media (max-width: 767px) {
  .navbar-collapse {
    position: static;
    background: white; }
    .navbar-collapse a {
      padding-right: 0px; }
  .nav-user {
    width: 30%;
    margin: auto;
    border-bottom: black 1px solid; }
    .nav-user .item {
      display: block;
      padding-bottom: 10px;
      text-align: center; }
  .logo-image {
    position: absolute; }
  .fixed-plugin {
    position: absolute; }
  .fixed-plugin .dropdown-menu {
    top: 0;
    width: 220px; }
  .nav > li {
    text-align: center; }
  .navbar-toggle .icon-bar {
    background: #0A3858; }
  .navbar-toggle {
    padding: 16px 10px; }
  .logo-container .logo {
    margin-left: 20px;
    posiiton: absolute; }
  #harmonia-static-blocks-page-top-toolbar .wrapper {
    text-align: center; }
  #harmonia-static-blocks-page-top-toolbar .logos li {
    display: block;
    margin-top: 10px; }
  #harmonia-static-blocks-page-top-toolbar .logos ul {
    padding-top: 20px; }
  #harmonia-static-blocks-page-top-toolbar .section-presentation {
    margin: 0; }
  #harmonia-static-blocks-page-top-toolbar .section-presentation {
    height: auto; }
  #harmonia-static-blocks-page-top-toolbar .carousel-indicators {
    bottom: -30px; }
  #harmonia-static-blocks-page-top-toolbar .carousel {
    min-height: 210px; } }

.carousel-control.left, .carousel-control.right {
  background-image: none; }

.flip {
  transform: rotate(180deg); }

.carousel-inner .item .img-wrap {
  display: block;
  max-height: 520px; }
  .carousel-inner .item .img-wrap img {
    overflow: hidden;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }

.carousel-caption {
  font-size: 0.9rem;
  top: 10%;
  left: 20%;
  right: 20%;
  padding: 0px;
  text-align: left;
  text-shadow: none; }
  .carousel-caption.long-quote {
    font-size: 0.8rem; }
  @media (max-width: 992px) {
    .carousel-caption {
      font-size: 0.8rem; }
      .carousel-caption.long-quote {
        font-size: 0.68rem; } }
  @media (max-width: 767px) {
    .carousel-caption {
      font-size: 0.7rem; }
      .carousel-caption.long-quote {
        font-size: 0.56rem; } }
  .carousel-caption h1, .carousel-caption h2, .carousel-caption h3, .carousel-caption h4, .carousel-caption h5, .carousel-caption h6 {
    font-weight: 100;
    color: white;
    border: none; }

.carousel-school-list-wrapper {
  background-color: rgba(29, 39, 49, 0.85);
  padding: 5px 20px 5px 20px; }
  .carousel-school-list-wrapper i {
    padding-right: 10px; }
  @media (max-width: 540px) {
    .carousel-school-list-wrapper h1, .carousel-school-list-wrapper h2 {
      margin: 0px; } }

.page-frontpage #main-wrapper {
  padding-top: 0; }

.student-teacher-buttons-wrapper {
  width: 100vw;
  /* <-- children as wide as the browser window (viewport) */
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  /* align left edge to the left edge of the viewport */
  background-color: #D9B310;
  text-align: center;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  padding: 50px 100px;
  margin-bottom: 0; }
  .student-teacher-buttons-wrapper img {
    margin-top: 10px;
    position: relative;
    width: 100%;
    max-width: 500px; }
  @media (max-width: 767px) {
    .student-teacher-buttons-wrapper {
      padding: 20px 50px;
      margin-bottom: 0; } }
  .student-teacher-buttons-wrapper h1 {
    border: none;
    color: #0A3858; }
  .student-teacher-buttons-wrapper .fancy-card {
    overflow: visible; }
  .student-teacher-buttons-wrapper .fancy-button {
    transition: all 2s ease-out, box-shadow 6s ease-out;
    background: #1D2731; }
    .student-teacher-buttons-wrapper .fancy-button:hover {
      background-color: #fff;
      color: #1D2731;
      box-shadow: 0px 0px 300px 300px rgba(255, 255, 255, 0.7);
      border-color: #fff; }

.video-wrapper {
  padding-top: 150px;
  padding-bottom: 150px;
  width: 100vw;
  /* <-- children as wide as the browser window (viewport) */
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  /* align left edge to the left edge of the viewport */
  background-image: url(../images/sheet-music.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover; }

.center-text {
  text-align: center; }

.parent-force-square {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 380px;
  width: 100%;
  position: relative;
  font-size: 1.05em; }
  .parent-force-square:after {
    content: "";
    display: block;
    padding-bottom: 100%; }
  .parent-force-square h1 {
    width: 100%;
    display: block;
    font-size: 2em; }
  .parent-force-square h2 {
    font-size: 1.3em; }
  .parent-force-square p {
    font-size: 0.9em; }
  @media (max-width: 992px) {
    .parent-force-square {
      font-size: 0.9em; } }
  @media (max-width: 540px) {
    .parent-force-square {
      font-size: 0.8em; } }

.tile {
  text-align: left;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f1f4;
  display: inline-block;
  background-size: cover;
  cursor: pointer;
  transition: all 0.4s ease-out;
  overflow: hidden;
  color: #1D2731;
  font-family: 'Cardo'; }

.tile img {
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  transition: all 0.4s ease-out; }

.tile .text {
  z-index: 99;
  position: absolute;
  padding: 0px;
  height: calc(100% - 60px);
  color: #1D2731; }

.tile h1 {
  color: #1D2731;
  font-weight: 300;
  margin: 0;
  text-shadow: 2px 2px 10px rgba(29, 39, 49, 0.3);
  border: none;
  background-color: #efd35e;
  opacity: 0.85;
  padding: 15px; }

.tile h2 {
  color: #1D2731;
  font-weight: 100;
  font-style: italic;
  transform: translateX(200px);
  padding: 20px 20px 0 20px;
  margin: 0; }

.tile p {
  color: #1D2731;
  font-weight: 300;
  padding: 20px 20px 0 20px;
  margin: 0;
  /*   opacity:0; */
  transform: translateX(-200px);
  transition-delay: 0.2s; }

.animate-text {
  opacity: 0;
  transition: all 0.6s ease-in-out; }

.tile:hover {
  background-color: #efd35e;
  box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.64);
  transform: scale(1.05); }

.tile:hover img {
  opacity: 0.1; }

.tile:hover .animate-text {
  transform: translateX(0);
  opacity: 1; }

.dots {
  position: absolute;
  bottom: -5%;
  right: 3%;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  color: currentColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }

.dots span {
  width: 5px;
  height: 5px;
  background-color: currentColor;
  border-radius: 50%;
  display: block;
  opacity: 0;
  transition: transform 0.4s ease-out, opacity 0.5s ease;
  transform: translateY(30px); }

.tile:hover span {
  opacity: 1;
  transform: translateY(0px); }

.dots span:nth-child(1) {
  transition-delay: 0.05s; }

.dots span:nth-child(2) {
  transition-delay: 0.1s; }

.dots span:nth-child(3) {
  transition-delay: 0.15s; }

@media (max-width: 1000px) {
  .wrap {
    flex-direction: column; } }

.front-page-wrapper #welcome h2 {
  font-size: 2em; }

.front-page-wrapper #welcome h3 {
  font-size: 1.6em; }

@media (max-width: 992px) {
  .front-page-wrapper #welcome h2 {
    font-size: 1.4em; }
  .front-page-wrapper #welcome h3 {
    font-size: 1.2em; } }

#front-row-1 video {
  min-width: 100%;
  max-width: 100%;
  position: absolute;
  width: auto;
  height: auto;
  z-index: -100;
  opacity: 0.1; }

#front-logo-image {
  width: 50%;
  height: 100%;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  border: 0px; }
  #front-logo-image img {
    padding: 30px;
    border: 0px;
    width: 80%; }
  @media (max-width: 992px) {
    #front-logo-image img {
      padding: 30px 0;
      width: 100%; } }

#frontpage-cover-text {
  text-align: center; }

#front-signup-buttons button {
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1.1;
  font-family: Sans-Serif;
  width: 100%;
  height: 100px;
  margin: 30px 0;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px; }

#front-company-vision {
  background-color: #3b698d;
  padding-top: 40px;
  padding-bottom: 40px; }
  #front-company-vision h4 {
    color: #FFFFFF;
    font-family: Sans-Serif;
    font-size: 2em; }
    @media (max-width: 767px) {
      #front-company-vision h4 {
        font-size: 1.5em; } }

#front-about-harmonia {
  background-color: #eee; }
  #front-about-harmonia .image {
    text-align: right; }
    @media (max-width: 992px) {
      #front-about-harmonia .image {
        text-align: center;
        margin-bottom: 30px; } }
    #front-about-harmonia .image img {
      max-width: 100%; }
  #front-about-harmonia .text {
    padding-top: 30px; }

#front-harmonia-for-you {
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: #FFFFFF; }
  #front-harmonia-for-you .for-you-button {
    transition-duration: 0.2s;
    font-family: 'Lato', sans-serif;
    color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 5px 0px #666;
    -moz-box-shadow: 0px 0px 5px 0px #666;
    box-shadow: 0px 0px 5px 0px #666;
    margin-bottom: 20px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    border-radius: 40px;
    font-size: 1.1em;
    padding: 15px 20px;
    width: 100%;
    height: 80px;
    text-align: center;
    background-color: #527b35;
    cursor: pointer; }
    @media (max-width: 767px) {
      #front-harmonia-for-you .for-you-button {
        height: 50px;
        padding: 8px 15px; } }
    #front-harmonia-for-you .for-you-button:hover {
      font-weight: bold; }
  #front-harmonia-for-you .for-you-detail {
    display: none;
    position: relative;
    background-color: #3b698d;
    color: #FFFFFF;
    padding: 20px 30px 40px 30px;
    margin: 0 0 50px 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px; }
    #front-harmonia-for-you .for-you-detail .for-you-detail-triangle {
      position: absolute;
      top: -25px;
      left: 10px;
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-bottom: 30px solid #3b698d; }

#front-list-products {
  background-color: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 50px; }
  #front-list-products .products-intro {
    padding-bottom: 70px; }
    #front-list-products .products-intro p {
      font-family: Sans-Serif;
      font-size: 1.5em; }

.front-product-item {
  border: 5px solid #fff;
  -webkit-box-shadow: 0px 0px 5px 0px #666;
  -moz-box-shadow: 0px 0px 5px 0px #666;
  box-shadow: 0px 0px 5px 0px #666;
  padding: 5px 20px 40px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }
  .front-product-item h3 {
    border-bottom: 1px solid gray;
    padding-bottom: 5px;
    text-align: center; }
    .front-product-item h3 a {
      color: #4f4b4c; }

.front-product-item-1 {
  background: #fceabb; }

.front-product-item-2 {
  background: #ccffcc; }

.front-product-item-3 {
  background: #DBF6FF; }

#front-about-us .illiac-logo img {
  max-width: 100%; }

#front-about-us .image-wrapper {
  text-align: center;
  margin-top: 30px; }
  @media (max-width: 767px) {
    #front-about-us .image-wrapper {
      margin-top: 80px; } }

#front-about-us .image {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
  overflow: hidden;
  border: 5px solid #fff;
  width: 150px;
  height: 150px;
  -webkit-box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
  display: inline-block; }

#front-about-us img.headshot {
  height: 150px;
  width: 150px; }

#front-about-us .employees {
  padding-top: 80px;
  padding-bottom: 100px; }

#front-about-us .full-name {
  color: #f0f1f4;
  text-align: center;
  font-size: 1.3em; }

#front-about-us .job-title {
  color: #f0f1f4;
  text-align: center;
  font-size: 1.1em;
  margin-bottom: 25px; }
  @media (max-width: 767px) {
    #front-about-us .job-title {
      margin-bottom: 10px; } }

#front-about-us .short-bio {
  text-align: center; }
  #front-about-us .short-bio p {
    font-size: 0.9em; }
    @media (max-width: 992px) {
      #front-about-us .short-bio p {
        font-size: 1em; } }

#front-experience {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f6f6f6; }
  #front-experience h4 {
    font-family: Sans-Serif;
    font-size: 2em; }
    @media (max-width: 767px) {
      #front-experience h4 {
        font-size: 1.5em; } }

#mailing-list-subscribe {
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  color: #1D2731; }

#very-important-info {
  display: none; }

#important-info {
  background-color: #f0f1f4;
  color: #1D2731; }

#download-go {
  padding: 5px 10px;
  display: inline-block;
  cursor: pointer; }

#no-subscription {
  position: relative;
  font-size: 0.65em;
  margin: 0 auto 20px auto; }

.app-download-button {
  max-width: 350px;
  display: block;
  font-size: 0.9em; }
  @media (max-width: 767px) {
    .app-download-button {
      font-size: 0.8em; } }

.page-students #main-wrapper, .page-teachers #main-wrapper {
  padding-top: 0; }

.app-download-cover {
  width: 100vw;
  /* <-- children as wide as the browser window (viewport) */
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  /* align left edge to the left edge of the viewport */
  background-color: #f0f1f4; }
  .app-download-cover .cover-logo {
    text-align: center;
    padding-top: 70px;
    padding-bottom: 50px; }
  .app-download-cover img {
    width: 320px; }
  .app-download-cover .cover-buttons {
    text-align: center;
    padding-top: 80px; }
    .app-download-cover .cover-buttons button {
      width: 250px; }
    @media (max-width: 767px) {
      .app-download-cover .cover-buttons {
        padding-top: 20px; } }

#main-wrapper.computer-lab {
  background-image: url(../images/computer-lab.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  padding-top: 150px; }
  #main-wrapper.computer-lab .lab-text-row {
    background-color: rgba(84, 96, 109, 0.9);
    padding: 30px 0 30px 0;
    margin-bottom: 100px; }

.page-harmonia-app .feature-row {
  border-bottom: 2px solid #5593d1;
  padding-bottom: 40px;
  padding-top: 10px; }
  .page-harmonia-app .feature-row:last-child {
    border-bottom: 0; }
  .page-harmonia-app .feature-row .image-wrapper {
    text-align: center; }
    .page-harmonia-app .feature-row .image-wrapper img {
      border: 5px solid #FFFFFF;
      box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
      width: 320px; }
  .page-harmonia-app .feature-row h2 {
    border-bottom: 1px solid #328CC1;
    margin-top: 0; }
    .page-harmonia-app .feature-row h2 .fa {
      font-size: 2.5em;
      mirgin-right: 10px;
      color: #328CC1; }

.page-harmonia-app .row-other-features .fa {
  font-size: 2em;
  margin-right: 10px;
  color: #328CC1; }

.page-harmonia-app .row-real-exercises li i {
  font-size: 1.5em;
  margin-right: 5px;
  color: #328CC1; }

.page-harmonia-app #page-bottom-download {
  background-color: #1D2731;
  padding: 60px 0 30px 0;
  font-size: 1.2em; }
  .page-harmonia-app #page-bottom-download .fancy-button-block {
    max-width: 400px;
    margin: 0 auto; }

.page-online-lessons #cover {
  background-image: url("/sites/all/themes/harmonia/images/darkflatblue.jpg");
  background-size: cover;
  padding: 10px 0 40px 0; }
  .page-online-lessons #cover h1 {
    color: #ffffff;
    border-bottom: 0;
    font-size: 2em; }
    @media (min-width: 767px) {
      .page-online-lessons #cover h1 {
        font-size: 3em; } }
    @media (min-width: 992px) {
      .page-online-lessons #cover h1 {
        font-size: 4em; } }
  .page-online-lessons #cover h4 {
    color: #FFFFFF;
    font-weight: 100; }
  .page-online-lessons #cover h3 {
    display: block;
    float: right;
    max-width: 767px;
    text-align: right;
    color: #FFFFFF;
    font-weight: 100; }
    .page-online-lessons #cover h3 span.bold {
      font-weight: bold; }

.page-online-lessons #how-it-works-wrapper {
  background-color: #DFFFD6; }
  .page-online-lessons #how-it-works-wrapper ul {
    padding-left: 0; }
  .page-online-lessons #how-it-works-wrapper li {
    position: relative;
    font-family: 'Lato', sans-serif;
    border: 0px;
    background: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #777;
    line-height: 1;
    margin: 5px 5px 5px 0;
    font-size: 1em;
    color: #4f4b4c;
    background-color: #777;
    color: #FFFFFF;
    padding: 5px 10px 5px 70px;
    margin-bottom: 10px; }
    .page-online-lessons #how-it-works-wrapper li i {
      position: absolute;
      left: 20px;
      font-size: 1.5em; }
    .page-online-lessons #how-it-works-wrapper li a {
      color: #9fcce0; }
      .page-online-lessons #how-it-works-wrapper li a:hover {
        color: #5593d1; }
    @media (max-width: 767px) {
      .page-online-lessons #how-it-works-wrapper li {
        font-size: 1em;
        padding: 5px 10px 5px 60px; } }
    @media (max-width: 540px) {
      .page-online-lessons #how-it-works-wrapper li {
        font-size: 0.8em;
        padding: 5px 10px 5px 60px; } }

.page-online-lessons .more-from-harmonia {
  text-align: center; }
  .page-online-lessons .more-from-harmonia h1 {
    color: #2E3ED1;
    border-bottom: 0;
    transform: scaleY(1.5);
    font-weight: 100; }
  .page-online-lessons .more-from-harmonia h2 {
    color: #2E3ED1; }
  .page-online-lessons .more-from-harmonia h3 {
    color: #2E3ED1; }

.page-online-lessons #benefit-wrapper {
  background-color: #DFFFD6; }

.page-online-lessons #page-bottom-sign-up {
  background-color: #DBF6FF;
  text-align: center; }

body.node-type-illiac-course a:link, body.node-type-illiac-course a:visited {
  color: #2E3ED1; }

body.node-type-illiac-course div#main-wrapper div.content {
  padding-left: 0;
  padding-right: 0; }

body.node-type-illiac-course .field-name-body {
  color: #F8F7F2; }

body.node-type-illiac-course #cover .container {
  background-color: rgba(255, 255, 255, 0.6); }

body.node-type-illiac-course #cover h1 {
  color: #000000;
  border-bottom: 0;
  font-size: 2em; }
  @media (min-width: 767px) {
    body.node-type-illiac-course #cover h1 {
      font-size: 3em; } }
  @media (min-width: 992px) {
    body.node-type-illiac-course #cover h1 {
      font-size: 4em; } }

body.node-type-illiac-course #cover h4 {
  color: #000000;
  font-weight: 100; }

body.node-type-illiac-course #cover h3 {
  display: block;
  float: right;
  max-width: 767px;
  text-align: right;
  color: #000000;
  font-weight: 100; }
  body.node-type-illiac-course #cover h3 span.bold {
    font-weight: bold; }

body.node-type-illiac-course h1 {
  margin-bottom: 0; }

body.node-type-illiac-course .main-body-wrapper {
  padding-top: 50px; }

body.node-type-illiac-course .main-body {
  box-shadow: 2px 2px 10px 1px #666;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: #b9bbbb;
  margin-top: 20px;
  margin-bottom: 50px; }
  body.node-type-illiac-course .main-body h1 {
    border-bottom: 0;
    color: #F8F7F2; }
  body.node-type-illiac-course .main-body p {
    color: #F8F7F2; }
  body.node-type-illiac-course .main-body .text-wrapper {
    padding-top: 20px; }
  body.node-type-illiac-course .main-body .icon {
    position: relative; }
    body.node-type-illiac-course .main-body .icon img {
      width: 150%;
      max-width: 220px;
      height: auto;
      position: absolute;
      left: -50%;
      top: -50px; }

body.node-type-illiac-course .file-listing {
  padding: 20px;
  font-weight: bold; }
  body.node-type-illiac-course .file-listing i {
    font-weight: normal; }

#colorbox button {
  transition-duration: 0s; }

.getting-started-link {
  margin-top: 20px;
  padding: 50px;
  background-color: rgba(240, 241, 244, 0.4); }
  .getting-started-link .getting-started-img {
    float: left;
    width: 100px;
    margin-right: 20px; }
    @media (max-width: 767px) {
      .getting-started-link .getting-started-img {
        float: none;
        display: block;
        margin: 0 auto; } }

.getting-started-sub-row {
  border-bottom: 4px solid #D9B310;
  border-left: 4px solid #D9B310;
  border-right: 4px solid #D9B310;
  padding: 10px; }
  .getting-started-sub-row:nth-child(odd) {
    background-color: #f0f1f4; }
  .getting-started-sub-row:nth-child(even) {
    background-color: #e1e3e9; }
  .getting-started-sub-row:first-child {
    border-top: 4px solid #D9B310; }
  .getting-started-sub-row, .getting-started-sub-row p, .getting-started-sub-row h3, .getting-started-sub-row h4 {
    color: #1D2731; }
  .getting-started-sub-row h3 {
    font-weight: 100;
    padding-bottom: 3px;
    border-bottom: 1px #778696 solid; }

.page-harmonia-for-teachers {
  /*
  .feature-row{
    h2 {
      .fa{
        font-size:2.5em;
        mirgin-right:10px;
        color:$dark-green;
      }
    }
  }*/ }
  .page-harmonia-for-teachers #teachers-download {
    background-color: #DFFFD6; }
    .page-harmonia-for-teachers #teachers-download .cover-logo {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 50px; }
    .page-harmonia-for-teachers #teachers-download img {
      width: 320px; }
    .page-harmonia-for-teachers #teachers-download .page-title {
      font-size: 2em; }
    .page-harmonia-for-teachers #teachers-download .cover-buttons {
      text-align: center;
      padding-top: 40px; }
      .page-harmonia-for-teachers #teachers-download .cover-buttons button {
        width: 250px; }
  .page-harmonia-for-teachers #free-for-teachers {
    background-color: #d99510;
    margin-bottom: 10px;
    text-align: center; }
    .page-harmonia-for-teachers #free-for-teachers h2 {
      font-weight: 100;
      color: #f0f1f4;
      margin-top: 10px; }
  .page-harmonia-for-teachers .feature-row {
    border-bottom: 2px solid #5593d1;
    padding-bottom: 40px;
    padding-top: 40px; }
    .page-harmonia-for-teachers .feature-row:last-child {
      border-bottom: 0; }
    .page-harmonia-for-teachers .feature-row .image-wrapper {
      text-align: center; }
      .page-harmonia-for-teachers .feature-row .image-wrapper img {
        border: 5px solid #FFFFFF;
        -webkit-box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
        box-shadow: 1px -1px 16px -3px rgba(0, 0, 0, 0.75);
        width: 320px; }
    .page-harmonia-for-teachers .feature-row h2 {
      border-bottom: 1px solid #e5e5e5;
      margin-top: 0; }
      .page-harmonia-for-teachers .feature-row h2 .fa {
        font-size: 2.5em;
        margin-right: 10px;
        color: #328CC1; }
  .page-harmonia-for-teachers .pricing-item {
    margin-bottom: 60px; }
    .page-harmonia-for-teachers .pricing-item .fa {
      font-size: 2.5em;
      margin-right: 10px;
      color: #328CC1; }

.content-sub-header {
  color: #efd35e;
  text-align: center; }

.browse-content-samples {
  margin-bottom: 30px; }

.teacher-features {
  padding-bottom: 50px; }

.course-management-video {
  padding-bottom: 40px; }

#page-bottom-sign-up {
  background-color: #54606d;
  border: 10px solid #f0f1f4;
  padding: 20px;
  margin-bottom: 30px;
  margin-top: 50px;
  font-size: 1em; }
  #page-bottom-sign-up li {
    list-style-type: circle; }
  @media (max-width: 767px) {
    #page-bottom-sign-up {
      font-size: 0.85em; } }

#serving-school-list {
  width: 100vw;
  /* <-- children as wide as the browser window (viewport) */
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  /* align left edge to the left edge of the viewport */
  background-image: url(../images/university-campus.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  padding-top: 140px;
  padding-bottom: 140px; }
  #serving-school-list .text-wrapper {
    background-color: rgba(29, 39, 49, 0.85);
    padding: 20px; }
  #serving-school-list i.fa {
    padding-right: 10px; }

.harmonia-pricing-background {
  width: 100vw;
  /* <-- children as wide as the browser window (viewport) */
  margin-left: calc(-1 * ((100vw - 100%) / 2));
  /* align left edge to the left edge of the viewport */
  background-image: url(../images/pennies.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 80px; }

.harmonia-pricing {
  background-color: rgba(29, 39, 49, 0.7);
  padding-bottom: 60px; }
  .harmonia-pricing .pricing-contents {
    margin: 0 auto;
    max-width: 550px; }
  .harmonia-pricing img {
    max-width: 100%; }
  .harmonia-pricing h5 {
    letter-spacing: 1px;
    padding-left: 20px;
    text-align: justify; }

.page-dashboard #main-wrapper, .page-submission #main-wrapper {
  background-color: #54606d; }

body.page-dashboard {
  color: #1D2731; }
  body.page-dashboard p {
    color: #1D2731; }

body.page-dashboard-instructor-add-site-license p {
  color: #f0f1f4; }

.dashboard-content {
  background-color: #f0f1f4; }
  .dashboard-content h1, .dashboard-content h2, .dashboard-content h3, .dashboard-content h4, .dashboard-content h5, .dashboard-content h6 {
    color: #1D2731; }

#content-library-viewer-wrapper h1, #content-library-viewer-wrapper h2, #content-library-viewer-wrapper h3, #content-library-viewer-wrapper h4, #content-library-viewer-wrapper h5, #content-library-viewer-wrapper h6 {
  color: #1D2731; }

#record-content-view {
  padding: 15px; }
  #record-content-view .not-formal {
    padding-left: 10px;
    background-color: #f0f1f4; }
    #record-content-view .not-formal td {
      padding-left: 5px; }

.form-item-field-course-status-value select option {
  padding: 5px; }

.quick-links {
  border: 1px solid #999;
  padding: 20px;
  background-color: #f0f1f4; }
  .quick-links h1, .quick-links h2, .quick-links h3, .quick-links h4, .quick-links h5, .quick-links h6, .quick-links p {
    color: #0A3858; }

#edit-field-course-status-value-wrapper {
  color: #f0f1f4; }

li.pager-current {
  background-color: #f0f1f4;
  border-radius: 15px; }

.course-invitation-code span {
  background-color: #328CC1;
  color: #f0f1f4;
  font-weight: bold;
  padding: 3px 10px;
  position: relative;
  left: 10px; }

.course-invitation-code a {
  font-size: 0.8em;
  position: relative;
  left: 10px; }

#record-download-wrapper form {
  padding-left: 10px; }
  #record-download-wrapper form select {
    margin-bottom: 5px;
    display: block; }

.dashboard-content #delete-set-wrapper, .dashboard-content #global-settings, .dashboard-content #add-from-library-button, .dashboard-content #fileupload {
  padding-left: 15px;
  padding-bottom: 10px; }

.ui-dialog .ui-dialog-titlebar-close {
  background-color: #f0f1f4;
  border: 2px solid #1D2731;
  color: #1D2731; }
  .ui-dialog .ui-dialog-titlebar-close:after {
    display: inline-block;
    font-weight: 100;
    font-size: 1.4em;
    position: relative;
    content: "X";
    top: -11px;
    left: -1px; }

#ui-datepicker-div {
  z-index: 9999 !important; }

#documents .ui-state-highlight {
  height: 100px;
  background-color: #666 !important;
  margin-bottom: 40px; }

#file-upload-template {
  display: none; }

.file-upload-item {
  height: 150px;
  max-width: 400px;
  padding-left: 10px; }
  .file-upload-item .progress {
    background-color: #b9d9ed; }

#documents .doc {
  transition: background-color 1000ms linear;
  background-color: #b9d9ed;
  border: 2px solid #1D2731;
  border-bottom: none;
  position: relative; }
  #documents .doc:last-child {
    border-bottom: 2px solid #1D2731; }

#documents .doc.newly-added {
  display: none;
  background-color: #efd35e; }

.doc-index-box {
  float: left;
  width: 70px;
  text-align: center;
  cursor: move; }

.doc-content {
  background-color: #f0f1f4;
  margin-left: 70px;
  padding: 10px 40px 10px 10px; }

input.doc-name {
  width: 100%; }

.doc-name-wrapper {
  margin-left: 30px; }

.doc-detail {
  margin-left: 30px; }

.file-name-wrapper {
  cursor: pointer; }

.doc-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer; }

.doc-detail {
  display: none; }

.caret-wrapper {
  width: 30px;
  float: left;
  text-align: center;
  cursor: pointer; }

.caret-wrapper i {
  transition: all .3s linear; }

.ui-selected {
  background-color: #ddfea3; }

.library-doc-item, .selected-doc {
  padding-right: 50px;
  position: relative; }

.library-doc-item i, .selected-doc i {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1em; }

#content-library-listing .selected {
  background-color: #999999; }

#content-library-list-of-selected-items {
  border: 2px solid #000;
  margin-top: 30px;
  background-color: #fed3e5; }

#content-library-doc-preview {
  height: 500px; }

#preview-spinner {
  line-height: 500px;
  text-align: center; }

#document-preview-spinner {
  text-align: center;
  line-height: 100%; }

#document-preview-header {
  position: relative;
  min-height: 30px; }

#document-preview-header #preview-left {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0; }

#document-preview-header #document-preview-title {
  margin: 0, 20px;
  text-align: center; }

#document-preview-header #preview-right {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  top: 0; }

.assignment-set {
  border-top: 6px solid #1D2731;
  padding-top: 10px;
  padding-bottom: 40px;
  background-color: #e1e3e9;
  position: relative;
  clear: both;
  overflow: hidden; }
  .assignment-set:last-child {
    border-bottom: 6px solid #1D2731; }

.set-index-box {
  float: left;
  border: 1px solid #1D2731;
  text-align: center;
  width: 60px;
  margin: 7px;
  background-color: #b9d9ed;
  color: #1D2731; }

.set-drag {
  cursor: move; }

.set-content {
  margin-left: 80px; }

.set-title-bar {
  overflow: hidden; }

.caret-wrapper {
  width: 30px;
  float: left;
  text-align: center;
  cursor: pointer; }

.caret-wrapper i {
  transition: all .3s linear; }

.set-title-bar h3 {
  margin-left: 30px;
  margin-top: 0; }

.set-edit {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.8em;
  padding: 5px; }

.set-detail {
  display: none; }

#student-license-product-information {
  font-weight: bold;
  margin-bottom: 25px; }

#link-student-view,
#link-back-to-instructor-view {
  position: absolute;
  font-family: 'Lato', sans-serif;
  top: 25px;
  right: 30px; }
  #link-student-view a,
  #link-back-to-instructor-view a {
    padding: 3px 10px;
    text-align: center;
    transition-duration: 0.2s; }
    #link-student-view a:hover,
    #link-back-to-instructor-view a:hover {
      opacity: 0.7; }
    @media (max-width: 540px) {
      #link-student-view a,
      #link-back-to-instructor-view a {
        width: 100%; } }
  @media (max-width: 540px) {
    #link-student-view,
    #link-back-to-instructor-view {
      position: inherit;
      margin-top: -20px;
      margin-bottom: 10px; } }

#link-back-to-instructor-view div {
  background-color: #874C00; }

body.page-dashboard-instructor h1 {
  margin-right: 150px; }
  @media (max-width: 992px) {
    body.page-dashboard-instructor h1 {
      margin-right: 120px; } }
  @media (max-width: 540px) {
    body.page-dashboard-instructor h1 {
      margin-right: 0; } }

body.page-dashboard-instructor .dialog-spinner-wrapper {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 7px; }

.sidebar-nav .navbar-collapse {
  position: static;
  padding: 0; }
  .sidebar-nav .navbar-collapse ul {
    margin: 0;
    width: 100%; }

.sidebar-nav .navbar {
  background-color: #1D2731; }

.sidebar-nav .navbar-nav > li {
  float: none; }

.sidebar-nav .navbar-default {
  border: none; }

.sidebar-nav .navbar-collapse {
  opacity: 1;
  border: none; }

#course-sidebar {
  border: 1px solid #777;
  background-color: #f0f1f4;
  padding: 0px; }
  #course-sidebar a {
    color: #0A3858; }
  #course-sidebar li {
    background-color: transparent; }
    #course-sidebar li.active a {
      background-color: #328CC1;
      color: white; }

.navbar li#people span {
  font-size: 0.68em;
  background-color: #DBF6FF;
  padding: 2px 4px;
  color: #4f4b4c;
  border: 1px solid #777;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }

.dashboard-sub-tabs {
  font-family: 'Lato', sans-serif;
  float: right;
  overflow: hidden; }

.dashboard-sub-tab {
  float: left;
  background-color: #b9d9ed;
  padding: 5px 10px 5px 10px;
  border: 1px solid #777;
  margin-right: 10px;
  cursor: pointer;
  border-bottom: 0; }
  .dashboard-sub-tab.active {
    background: #328CC1;
    color: #FFFFFF;
    box-shadow: 0px 3px 15px 1px #1D2731; }

#dashboard-instructor-main-row .dashboard-content .course-info-sub-tab-content {
  border-top: 1px solid #777;
  clear: both;
  display: none; }

#dashboard-instructor-main-row .dashboard-content .course-info-sub-tab-content.active {
  display: block; }

#dashboard-instructor-main-row .dashboard-content .course-info-header {
  margin-top: 20px;
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731; }
  #dashboard-instructor-main-row .dashboard-content .course-info-header .info-header-inner {
    margin: 20px; }

.site-license-info {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  margin-top: 20px; }
  .site-license-info #add-new-license {
    float: right; }

.site-license-info label {
  margin-bottom: 20px; }

#site-license-info-inner {
  padding: 10px 30px 10px 30px; }

.course-description {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  margin-top: 20px;
  overflow: hidden; }
  .course-description .course-description-inner {
    margin: 20px; }
  .course-description .edit {
    float: right; }

a#dashboard-instructor-create-new-course {
  font-size: 1em; }

/** People tab **/
#dashboard-instructor-main-row .people-sub-tab-content {
  border-top: 1px solid #777;
  clear: both;
  display: none;
  padding: 10px;
  min-height: 100px; }

#dashboard-instructor-main-row .people-sub-tab-content.active {
  display: block; }

#dashboard-instructor-main-row .spinner {
  display: inline-block; }

#dashboard-instructor-main-row table#ta-section-table {
  width: 100%;
  margin: 30px 0 30px 0; }

#dashboard-instructor-main-row table#ta-section-table td {
  border: 1px solid #ddd;
  padding: 5px; }

#dashboard-instructor-main-row table#ta-section-table th {
  border: 1px solid #ddd;
  background-color: #eeffee;
  padding: 5px; }

#dashboard-instructor-main-row #people-instructors, #dashboard-instructor-main-row #people-tas, #dashboard-instructor-main-row #people-students, #dashboard-instructor-main-row #people-student-requests {
  position: relative;
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  padding: 0 20px 20px 30px;
  margin-top: 20px; }
  #dashboard-instructor-main-row #people-instructors a, #dashboard-instructor-main-row #people-tas a, #dashboard-instructor-main-row #people-students a, #dashboard-instructor-main-row #people-student-requests a {
    color: #4f4b4c; }
  #dashboard-instructor-main-row #people-instructors span.pending, #dashboard-instructor-main-row #people-tas span.pending, #dashboard-instructor-main-row #people-students span.pending, #dashboard-instructor-main-row #people-student-requests span.pending {
    background-color: #328CC1;
    padding: 3px 10px;
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 0.8em; }
  #dashboard-instructor-main-row #people-instructors button.remove, #dashboard-instructor-main-row #people-instructors button.reject, #dashboard-instructor-main-row #people-tas button.remove, #dashboard-instructor-main-row #people-tas button.reject, #dashboard-instructor-main-row #people-students button.remove, #dashboard-instructor-main-row #people-students button.reject, #dashboard-instructor-main-row #people-student-requests button.remove, #dashboard-instructor-main-row #people-student-requests button.reject {
    background-color: #871b1b;
    color: #f0f1f4; }
    #dashboard-instructor-main-row #people-instructors button.remove:hover, #dashboard-instructor-main-row #people-instructors button.reject:hover, #dashboard-instructor-main-row #people-tas button.remove:hover, #dashboard-instructor-main-row #people-tas button.reject:hover, #dashboard-instructor-main-row #people-students button.remove:hover, #dashboard-instructor-main-row #people-students button.reject:hover, #dashboard-instructor-main-row #people-student-requests button.remove:hover, #dashboard-instructor-main-row #people-student-requests button.reject:hover {
      background-color: #1D2731;
      color: red; }
    #dashboard-instructor-main-row #people-instructors button.remove a, #dashboard-instructor-main-row #people-instructors button.reject a, #dashboard-instructor-main-row #people-tas button.remove a, #dashboard-instructor-main-row #people-tas button.reject a, #dashboard-instructor-main-row #people-students button.remove a, #dashboard-instructor-main-row #people-students button.reject a, #dashboard-instructor-main-row #people-student-requests button.remove a, #dashboard-instructor-main-row #people-student-requests button.reject a {
      color: #f0f1f4; }
    #dashboard-instructor-main-row #people-instructors button.remove:hover a, #dashboard-instructor-main-row #people-instructors button.reject:hover a, #dashboard-instructor-main-row #people-tas button.remove:hover a, #dashboard-instructor-main-row #people-tas button.reject:hover a, #dashboard-instructor-main-row #people-students button.remove:hover a, #dashboard-instructor-main-row #people-students button.reject:hover a, #dashboard-instructor-main-row #people-student-requests button.remove:hover a, #dashboard-instructor-main-row #people-student-requests button.reject:hover a {
      color: red; }

#dashboard-instructor-main-row #harmonia-user-dashboard-instructor-editinstructorsform,
#dashboard-instructor-main-row #harmonia-user-dashboard-instructor-edittasform {
  margin-top: 20px;
  border-top: 1px solid #777; }

#dashboard-instructor-main-row .edit-people-button {
  position: absolute;
  top: 0;
  right: 0; }

#dashboard-instructor-main-row button.remove, #dashboard-instructor-main-row button.reject {
  background-color: #871b1b;
  color: #f0f1f4; }
  #dashboard-instructor-main-row button.remove:hover, #dashboard-instructor-main-row button.reject:hover {
    background-color: #1D2731;
    color: red; }

/** Assignment tab **/
.assignment-set-edit #set-title-wrapper input {
  width: 80%;
  max-width: 550px; }

.assignment-set-edit .file-name-wrapper {
  padding-top: 5px; }
  .assignment-set-edit .file-name-wrapper i.fa-eye {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.2em; }

.assignment-set-edit .doc-detail input {
  margin-right: 5px; }

.assignment-details {
  padding-bottom: 20px; }
  .assignment-details > div:not(.dashboard-sub-tabs), .assignment-details > h2 {
    padding-left: 20px; }

button.delete-assignment {
  background-color: #871b1b;
  color: #f0f1f4; }
  button.delete-assignment:hover {
    background-color: #1D2731;
    color: red; }

body.page-dashboard-instructor-assignment .assignment-detail-buttons {
  clear: both;
  border-top: 1px solid #4f4b4c;
  padding-top: 10px; }

.assignment-listing .assignments-sub-tab-content {
  border-top: 1px solid #777;
  clear: both;
  padding-top: 20px; }

.assignment-listing .assignment-group {
  margin-bottom: 10px; }
  .assignment-listing .assignment-group .assignment-entity {
    padding: 1px 20px 20px 20px;
    border-top: 1px solid #9fcce0; }

.assignment-listing .assignment-group-title,
.assignment-listing .assignment-unassigned-group-title {
  font-family: 'Lato', sans-serif;
  transition-duration: 0.2s;
  font-size: 1.4em;
  padding: 10px 10px 20px 10px;
  background-color: #3b698d;
  color: #f0f1f4;
  cursor: pointer; }
  @media (max-width: 767px) {
    .assignment-listing .assignment-group-title,
    .assignment-listing .assignment-unassigned-group-title {
      font-size: 1.2em; } }
  @media (max-width: 540px) {
    .assignment-listing .assignment-group-title,
    .assignment-listing .assignment-unassigned-group-title {
      font-size: 1.1em; } }
  .assignment-listing .assignment-group-title button.edit-group,
  .assignment-listing .assignment-unassigned-group-title button.edit-group {
    font-size: 0.5em;
    padding: 4px 10px;
    margin: 0 10px 0 0;
    position: relative;
    top: -4px; }

.assignment-listing .assignment-unassigned-group-title {
  cursor: default;
  text-align: center;
  padding: 5px 5px 5px 5px;
  background-color: #778696; }

.assignment-listing .assignment-no-group-content {
  border: 1px solid #527b35;
  border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px; }
  .assignment-listing .assignment-no-group-content .assignment-entity {
    border-top: 1px solid #bcd39c;
    padding: 1px 20px 20px 20px; }

.assignment-listing .group-toggle {
  height: 100%;
  width: 30px;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  float: left; }

.assignment-listing .assignment-group .assignment-group-content {
  display: none;
  clear: both;
  border: 1px solid #3b698d;
  border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px; }

.assignment-listing .assignment-group.expanded .assignment-group-content {
  display: block; }

.assignment-listing #harmonia-user-dashboard-instructor-editassignmentform .form-item {
  margin-bottom: 20px; }

.assignment-listing #harmonia-user-dashboard-instructor-editassignmentform .date-padding {
  float: none; }

.assignment-ordering .edit-assignment-group-content {
  border-top: 1px solid #777;
  padding-top: 10px;
  clear: both; }
  .assignment-ordering .edit-assignment-group-content > p, .assignment-ordering .edit-assignment-group-content > h2 {
    padding-left: 20px; }

.assignment-ordering .assignment-group {
  margin-bottom: 10px;
  background-color: #b9d9ed;
  border: 1px solid #328CC1;
  border-top: 10px solid #328CC1;
  padding: 10px 10px 20px 10px; }

.assignment-ordering .assignment-group-title {
  font-size: 1.4em;
  color: #3b698d !important; }

.assignment-ordering table.assignment-group-title-table {
  width: 100%; }
  .assignment-ordering table.assignment-group-title-table tbody {
    border: none; }

.assignment-ordering .assignment-unassigned-group-title {
  font-size: 1.4em;
  padding: 5px 5px 5px 5px;
  margin: 5px 0 5px 0;
  background-color: #3b698d;
  color: #FFFFFF;
  text-align: center;
  clear: both; }

.assignment-ordering #add-group-button {
  cursor: pointer;
  clear: both; }

.assignment-ordering .group-toggle {
  width: 30px;
  text-align: center;
  vertical-align: middle; }
  .assignment-ordering .group-toggle i {
    display: inherit; }

.assignment-ordering td.group-title-text {
  font-family: 'Lato', sans-serif;
  transition-duration: 0.2s; }
  .assignment-ordering td.group-title-text .edit-input-wrapper.active {
    width: 95%; }
    .assignment-ordering td.group-title-text .edit-input-wrapper.active input {
      width: 100%;
      color: #777;
      padding-left: 5px; }
  @media (max-width: 767px) {
    .assignment-ordering td.group-title-text {
      font-size: 0.8em; } }

.assignment-ordering .assignment-group-content-wrapper {
  margin: 15px; }

.assignment-ordering .assignment-group .assignment-group-content {
  display: none;
  clear: both;
  border: 10px solid #FFFFFF;
  padding: 0 20px 20px 20px;
  background-color: #e1e3e9;
  box-shadow: 0px 0px 25px 2px rgba(240, 241, 244, 0.7); }

.assignment-ordering .assignment-group.expanded .assignment-group-content {
  display: block; }

.assignment-ordering .assignment-no-group-content {
  min-height: 90px;
  border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  border: 1px solid #3b698d; }

.assignment-ordering .assignment-entity {
  margin: 25px 0 10px 15px;
  border-bottom: 1px solid #874C00;
  clear: both; }

.assignment-ordering #submit-button {
  clear: both; }

.assignment-ordering #assignment-order-submit {
  display: inline-block;
  clear: both; }

.assignment-ordering #spinner {
  display: inline-block; }

.assignment-ordering .assignment-edit {
  float: right;
  margin-right: 10px; }

.assignment-ordering .edit-input-wrapper {
  display: inline-block; }

.assignment-ordering .delete-group {
  vertical-align: middle;
  width: 40px; }
  .assignment-ordering .delete-group button {
    background-color: #871b1b;
    color: #f0f1f4;
    padding: 3px;
    margin: 0px; }
    .assignment-ordering .delete-group button:hover {
      background-color: #1D2731;
      color: red; }
    .assignment-ordering .delete-group button i {
      display: table-cell; }

.assignment-due-day-extension > h2, .assignment-due-day-extension > h3 {
  padding-left: 20px; }

.assignment-due-day-extension #course-information {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  padding: 20px; }

.assignment-due-day-extension .due-day-extension {
  border-top: 1px solid #874C00;
  padding: 10px 10px 10px 20px; }

.assignment-due-day-extension button.delete-extension {
  background-color: #871b1b;
  color: #f0f1f4; }
  .assignment-due-day-extension button.delete-extension:hover {
    background-color: #1D2731;
    color: red; }

.assignment-due-day-extension .extension-entry {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  background-color: #b9d9ed;
  padding: 20px;
  margin-bottom: 20px; }

.assignment-group-form-divider {
  padding-bottom: 20px;
  padding-top: 10px; }

body.page-dashboard-instructor-assignment-add input[type='text'],
body.page-dashboard-instructor-assignment-edit input[type='text'],
body.page-dashboard-instructor-assignment-group input[type='text'] {
  background-color: white; }

body.page-dashboard-instructor-assignment-add .form-item,
body.page-dashboard-instructor-assignment-edit .form-item,
body.page-dashboard-instructor-assignment-group .form-item {
  margin-top: 0;
  margin-bottom: 0; }

body.page-dashboard-instructor-assignment-add .form-item-title, body.page-dashboard-instructor-assignment-add .form-item-short-title,
body.page-dashboard-instructor-assignment-edit .form-item-title,
body.page-dashboard-instructor-assignment-edit .form-item-short-title,
body.page-dashboard-instructor-assignment-group .form-item-title,
body.page-dashboard-instructor-assignment-group .form-item-short-title {
  padding-left: 20px; }
  body.page-dashboard-instructor-assignment-add .form-item-title label, body.page-dashboard-instructor-assignment-add .form-item-short-title label,
  body.page-dashboard-instructor-assignment-edit .form-item-title label,
  body.page-dashboard-instructor-assignment-edit .form-item-short-title label,
  body.page-dashboard-instructor-assignment-group .form-item-title label,
  body.page-dashboard-instructor-assignment-group .form-item-short-title label {
    display: block; }

body.page-dashboard-instructor-assignment-add #assignment-name-and-short-name,
body.page-dashboard-instructor-assignment-add .form-item-group,
body.page-dashboard-instructor-assignment-add .file-type-wrapper,
body.page-dashboard-instructor-assignment-add .timed-assignment-wrapper,
body.page-dashboard-instructor-assignment-add .misc-settings,
body.page-dashboard-instructor-assignment-add .form-item-assign-date,
body.page-dashboard-instructor-assignment-add .form-item-due-date,
body.page-dashboard-instructor-assignment-add .delay-showing-grade-wrapper,
body.page-dashboard-instructor-assignment-add .form-item-file,
body.page-dashboard-instructor-assignment-add .text-format-wrapper,
body.page-dashboard-instructor-assignment-edit #assignment-name-and-short-name,
body.page-dashboard-instructor-assignment-edit .form-item-group,
body.page-dashboard-instructor-assignment-edit .file-type-wrapper,
body.page-dashboard-instructor-assignment-edit .timed-assignment-wrapper,
body.page-dashboard-instructor-assignment-edit .misc-settings,
body.page-dashboard-instructor-assignment-edit .form-item-assign-date,
body.page-dashboard-instructor-assignment-edit .form-item-due-date,
body.page-dashboard-instructor-assignment-edit .delay-showing-grade-wrapper,
body.page-dashboard-instructor-assignment-edit .form-item-file,
body.page-dashboard-instructor-assignment-edit .text-format-wrapper,
body.page-dashboard-instructor-assignment-group #assignment-name-and-short-name,
body.page-dashboard-instructor-assignment-group .form-item-group,
body.page-dashboard-instructor-assignment-group .file-type-wrapper,
body.page-dashboard-instructor-assignment-group .timed-assignment-wrapper,
body.page-dashboard-instructor-assignment-group .misc-settings,
body.page-dashboard-instructor-assignment-group .form-item-assign-date,
body.page-dashboard-instructor-assignment-group .form-item-due-date,
body.page-dashboard-instructor-assignment-group .delay-showing-grade-wrapper,
body.page-dashboard-instructor-assignment-group .form-item-file,
body.page-dashboard-instructor-assignment-group .text-format-wrapper {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  padding: 10px 10px 10px 20px;
  margin-top: 1em; }

body.page-dashboard-instructor-assignment-add .form-item-timed-assignment label,
body.page-dashboard-instructor-assignment-add .form-item-delay-showing-grade label,
body.page-dashboard-instructor-assignment-edit .form-item-timed-assignment label,
body.page-dashboard-instructor-assignment-edit .form-item-delay-showing-grade label,
body.page-dashboard-instructor-assignment-group .form-item-timed-assignment label,
body.page-dashboard-instructor-assignment-group .form-item-delay-showing-grade label {
  font-size: 1em;
  font-weight: bold; }

body.page-dashboard-instructor-assignment-add .form-item-timed-assignment-time label,
body.page-dashboard-instructor-assignment-add .form-item-due-date-date label,
body.page-dashboard-instructor-assignment-add .form-item-due-date-time label,
body.page-dashboard-instructor-assignment-add .form-item-assign-date-date label,
body.page-dashboard-instructor-assignment-add .form-item-assign-date-time label,
body.page-dashboard-instructor-assignment-add .form-item-date-showing-grade label,
body.page-dashboard-instructor-assignment-edit .form-item-timed-assignment-time label,
body.page-dashboard-instructor-assignment-edit .form-item-due-date-date label,
body.page-dashboard-instructor-assignment-edit .form-item-due-date-time label,
body.page-dashboard-instructor-assignment-edit .form-item-assign-date-date label,
body.page-dashboard-instructor-assignment-edit .form-item-assign-date-time label,
body.page-dashboard-instructor-assignment-edit .form-item-date-showing-grade label,
body.page-dashboard-instructor-assignment-group .form-item-timed-assignment-time label,
body.page-dashboard-instructor-assignment-group .form-item-due-date-date label,
body.page-dashboard-instructor-assignment-group .form-item-due-date-time label,
body.page-dashboard-instructor-assignment-group .form-item-assign-date-date label,
body.page-dashboard-instructor-assignment-group .form-item-assign-date-time label,
body.page-dashboard-instructor-assignment-group .form-item-date-showing-grade label {
  font-weight: normal;
  font-size: 0.8em; }

body.page-dashboard-instructor-assignment-add .form-item-assign-date,
body.page-dashboard-instructor-assignment-add .form-item-due-date,
body.page-dashboard-instructor-assignment-edit .form-item-assign-date,
body.page-dashboard-instructor-assignment-edit .form-item-due-date,
body.page-dashboard-instructor-assignment-group .form-item-assign-date,
body.page-dashboard-instructor-assignment-group .form-item-due-date {
  width: 100%; }

body.page-dashboard-instructor-assignment-add .date-padding,
body.page-dashboard-instructor-assignment-edit .date-padding,
body.page-dashboard-instructor-assignment-group .date-padding {
  float: none; }

body.page-dashboard-instructor-assignment-add .form-item-file #edit-file-remove-button,
body.page-dashboard-instructor-assignment-edit .form-item-file #edit-file-remove-button,
body.page-dashboard-instructor-assignment-group .form-item-file #edit-file-remove-button {
  padding: 0 5px;
  background-color: #871b1b;
  color: #f0f1f4; }
  body.page-dashboard-instructor-assignment-add .form-item-file #edit-file-remove-button:hover,
  body.page-dashboard-instructor-assignment-edit .form-item-file #edit-file-remove-button:hover,
  body.page-dashboard-instructor-assignment-group .form-item-file #edit-file-remove-button:hover {
    background-color: #1D2731;
    color: red; }

.assignment-group-edit-message {
  padding: 20px;
  font-weight: bold;
  font-size: 1.4em; }

button#assignment-group-delete {
  background-color: #871b1b;
  color: #f0f1f4; }
  button#assignment-group-delete:hover {
    background-color: #1D2731;
    color: red; }

/** records tab **/
.instructor-record-tab {
  padding: 20px; }
  .instructor-record-tab span.grade-overwrite {
    font-weight: bold;
    color: #F0F; }
  .instructor-record-tab table#record-detail tr {
    border-bottom: 1px solid #eee; }
  .instructor-record-tab table#record-detail td {
    padding: 5px; }
  .instructor-record-tab button.remove-record {
    background-color: #871b1b;
    color: #f0f1f4; }
    .instructor-record-tab button.remove-record:hover {
      background-color: #1D2731;
      color: red; }

.record-listing .record-sub-tab-content {
  border-top: 1px solid #777;
  clear: both;
  display: none; }

.record-listing .record-sub-tab-content.active {
  display: inherit; }

.record-listing #record-sub-tab-content-0 .assignment-group {
  margin-top: 10px; }
  .record-listing #record-sub-tab-content-0 .assignment-group .assignment-entity {
    padding: 1px 20px 20px 20px;
    border-top: 1px solid #e5e5e5; }

.record-listing #record-sub-tab-content-0 .assignment-group-title,
.record-listing #record-sub-tab-content-0 .assignment-unassigned-group-title {
  font-family: 'Lato', sans-serif;
  transition-duration: 0.2s;
  font-size: 1.4em;
  padding: 10px 10px 20px 10px;
  background-color: #3b698d;
  color: #FFFFFF;
  cursor: pointer; }
  @media (max-width: 767px) {
    .record-listing #record-sub-tab-content-0 .assignment-group-title,
    .record-listing #record-sub-tab-content-0 .assignment-unassigned-group-title {
      font-size: 1.2em; } }
  @media (max-width: 540px) {
    .record-listing #record-sub-tab-content-0 .assignment-group-title,
    .record-listing #record-sub-tab-content-0 .assignment-unassigned-group-title {
      font-size: 1.1em; } }

.record-listing #record-sub-tab-content-0 .assignment-unassigned-group-title {
  cursor: default;
  text-align: center;
  padding: 5px 5px 5px 5px;
  background-color: #778696;
  margin-top: 10px; }

.record-listing #record-sub-tab-content-0 .assignment-no-group-content {
  border: 1px solid #0A3858; }
  .record-listing #record-sub-tab-content-0 .assignment-no-group-content .assignment-entity {
    border-top: 1px solid #b9d9ed;
    padding: 1px 20px 20px 20px; }

.record-listing #record-sub-tab-content-0 .group-toggle {
  height: 100%;
  width: 30px;
  margin-right: 30px;
  text-align: center;
  vertical-align: middle;
  float: left; }

.record-listing #record-sub-tab-content-0 .assignment-group .assignment-group-content {
  display: none;
  clear: both;
  border: 1px solid #4f4b4c;
  border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px; }

.record-listing #record-sub-tab-content-0 .assignment-group.expanded .assignment-group-content {
  display: block; }

.record-listing #table-format-selector {
  font-size: 1.2em;
  background-color: #3b698d;
  color: #FFFFFF;
  font-family: 'Lato', sans-serif;
  padding: 15px 15px 10px 15px;
  margin-top: 10px; }
  .record-listing #table-format-selector select {
    color: #000000;
    margin-left: 10px;
    background-color: white; }

.record-listing #record-table-wrapper {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #3b698d; }
  .record-listing #record-table-wrapper th {
    padding: 5px; }
  .record-listing #record-table-wrapper tr > th:nth-child(n+2) {
    border-left: 1px solid #e5e5e5; }
  .record-listing #record-table-wrapper td {
    color: #f0f1f4;
    background-color: #778696; }
  .record-listing #record-table-wrapper tr > th:first-child {
    margin-right: 20px; }
  .record-listing #record-table-wrapper tr > td:nth-child(n+2) {
    text-align: center;
    padding: 4px 40px;
    border-left: 1px solid #f0f1f4; }
  .record-listing #record-table-wrapper tr > td:first-child {
    padding: 10px;
    background-color: #333a42; }

.record-listing #record-full-spreadsheet_filter,
.record-listing #record-full-spreadsheet_info {
  display: none; }

.record-listing .point-achieved, .record-listing .percent-correct, .record-listing .letter-grade {
  display: none; }

.record-listing .point-achieved.active, .record-listing .percent-correct.active, .record-listing .letter-grade.active {
  display: inline; }

.record-listing #record-sub-tab-content-2 #csv-configuration {
  font-family: 'Lato', sans-serif;
  padding: 20px;
  background-color: #54606d;
  color: #FFFFFF;
  font-size: 1.2em;
  margin-top: 10px; }
  @media (max-width: 767px) {
    .record-listing #record-sub-tab-content-2 #csv-configuration {
      font-size: 1em; } }

.record-listing #record-sub-tab-content-2 input[type=checkbox] {
  /* Double-sized Checkboxes */
  transform: scale(1.3);
  padding: 10px;
  margin-right: 10px; }
  @media (max-width: 767px) {
    .record-listing #record-sub-tab-content-2 input[type=checkbox] {
      transform: scale(1); } }

.record-listing #record-sub-tab-content-2 .assignment-group {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  padding-bottom: 20px;
  margin-top: 10px;
  font-size: 1.2em; }
  @media (max-width: 767px) {
    .record-listing #record-sub-tab-content-2 .assignment-group {
      font-size: 1em; } }

.record-listing #record-sub-tab-content-2 .group-title-wrapper {
  background-color: #3b698d;
  padding: 0 20px 10px 20px;
  color: #FFFFFF;
  font-family: 'Lato', sans-serif;
  font-size: 1.3em;
  margin-bottom: 10px; }
  @media (max-width: 767px) {
    .record-listing #record-sub-tab-content-2 .group-title-wrapper {
      font-size: 1.1em; } }

.record-listing #record-sub-tab-content-2 .assignment-entity-wrapper {
  margin-left: 20px; }

.record-listing #record-sub-tab-content-2 #check-all, .record-listing #record-sub-tab-content-2 #check-none {
  margin-left: 30px; }

.record-listing #record-sub-tab-content-2 select {
  color: #000000;
  background-color: #f0f1f4; }

.form-item-school-name-text input,
.form-item-course-title input,
.form-item-site-license input {
  max-width: 100%; }

.form-item-course-title,
.form-item-academic-term,
.form-type-radios.form-item-license-type,
.form-item-site-license,
.form-item-year,
#harmonia-user-dashboard-instructor-createcourseform > div > .container-inline-date {
  border: 1px solid #328CC1;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #e1e3e9; }

#create-course-form-school {
  border: 1px solid #328CC1;
  border-radius: 5px;
  padding: 0 20px 20px 20px;
  margin-bottom: 20px;
  background-color: #e1e3e9; }

#create-course-form-student-license {
  border: 1px solid #328CC1;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #e1e3e9; }

/*
#create-course-form-dates .container-inline-date {
  border-top:1px solid #C9E8F2;
  padding:10px;
}*/
#create-course-form-student-license .container-inline-date .date-padding,
#harmonia-user-dashboard-instructor-createcourseform > div > .container-inline-date .date-padding {
  float: none; }

#create-course-form-student-license ul {
  list-style-type: disc;
  margin: 20px 0 20px 0; }

#create-course-form-dates > .container-inline-date:nth-of-type(1),
#create-course-form-dates > .container-inline-date:nth-of-type(2),
#student-free-access-end-date-container {
  border: 1px solid #328CC1;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #b9d9ed; }

#dashboard-ta-main-row .dashboard-content {
  /*
  .record-sub-tab{
    float:left;
    background-color:#eee;
    padding:5px 10px 5px 10px;
    border:1px solid #aaa;
    margin-right:10px;
  }
  .record-sub-tab.active{
    background: #87c7d9;
  }
  #record-sub-tabs{
    margin-bottom:20px;
    overflow: hidden;
  }
  .record-sub-tab-content{
    clear:both;
    display:none;
  }
  .record-sub-tab-content.active{
    display:block;
  }

  #record-table-wrapper{
    width: 100%;
    margin: 0 auto;
    border:1px solid #000;
    margin-top:40px;
  }


  #record-table-wrapper th,
  #record-table-wrapper td{

  }

  .point-achieved, .percent-correct, .letter-grade{
    display:none;
  }
  .point-achieved.active, .percent-correct.active, .letter-grade.active{
    display:inline;
  }

  #record-sub-tab-content-2 .assignment-group{
    border:1px solid #999;
    padding: 20px;
    margin:10px;
  }
  #record-sub-tab-content-2 .assignment-group .assignment-entity{
    margin-left:20px;
  }


  #check-none-all{
    background-color:#eeffee;
    padding:20px;
  }
  #check-all, #check-none{

    margin-top:20px;
    margin-left:30px;

  }*/ }
  #dashboard-ta-main-row .dashboard-content .course-info-header {
    margin-top: 20px;
    background-color: #f0f1f4;
    border-top: 1px solid #3b698d;
    border-bottom: 1px solid #3b698d;
    color: #1D2731; }
    #dashboard-ta-main-row .dashboard-content .course-info-header .info-header-inner {
      margin: 20px; }

#dashboard-student-main-row.row #content-tab-1.dashboard-content {
  padding-bottom: 10px; }
  #dashboard-student-main-row.row #content-tab-1.dashboard-content > div, #dashboard-student-main-row.row #content-tab-1.dashboard-content > h3 {
    padding-left: 10px; }

#dashboard-student-main-row.row #content-tab-0.dashboard-content .course-info-header {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  padding: 20px; }

#dashboard-student-main-row.row .course-description {
  padding: 20px; }

#dashboard-student-main-row.row .assignment-group-title,
#dashboard-student-main-row.row .assignment-unassigned-group-title {
  font-family: 'Lato', sans-serif;
  transition-duration: 0.2s;
  font-size: 1.4em;
  padding: 5px 5px 5px 5px;
  background-color: #0A3858;
  color: #f0f1f4;
  cursor: pointer; }
  @media (max-width: 767px) {
    #dashboard-student-main-row.row .assignment-group-title,
    #dashboard-student-main-row.row .assignment-unassigned-group-title {
      font-size: 1.2em; } }
  @media (max-width: 540px) {
    #dashboard-student-main-row.row .assignment-group-title,
    #dashboard-student-main-row.row .assignment-unassigned-group-title {
      font-size: 1.1em; } }

#dashboard-student-main-row.row .assignment-group {
  margin-bottom: 50px; }

#dashboard-student-main-row.row .assignment-unassigned-group-title {
  cursor: default;
  text-align: center;
  padding: 5px 5px 5px 5px;
  background-color: #328CC1; }

#dashboard-student-main-row.row .assignment-entity {
  padding: 1px 20px 20px 20px;
  border-bottom: 1px solid #9fcce0;
  border-left: 1px solid #0A3858;
  border-right: 1px solid #0A3858; }

.form-item-classcode {
  color: #f0f1f4; }

.enrollment-request {
  border-top: 1px solid #874C00;
  margin-top: 20px;
  padding-bottom: 20px; }

button.cancel-enrollment-request {
  background-color: #871b1b;
  color: #f0f1f4; }
  button.cancel-enrollment-request:hover {
    background-color: #1D2731;
    color: red; }

#dashboard-student-payment-reminder {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 30px 30px 30px;
  background-color: #F1E5AE;
  margin-bottom: 20px; }

#dashboard-student-payment-reminder.urgent {
  background-color: #d93f3f; }

#dashboard-student-payment-reminder h3 {
  font-weight: bold;
  font-size: 1.5em;
  color: #000; }

form#fastspring-student-license-order-form {
  margin-top: 30px; }

#forum table {
  width: 100%;
  margin-top: 30px;
  border-top: 0; }
  #forum table a {
    color: #000000; }
  #forum table .submitted {
    font-size: 0.8em;
    color: #777; }
  #forum table td.icon {
    text-align: center; }

#forum tbody {
  border-top: 0; }
  #forum tbody tr {
    border-bottom: 1px solid #874C00; }
  #forum tbody td {
    padding-top: 20px; }

#forum th {
  background-color: #527b35;
  color: #FFFFFF;
  font-family: 'Lato', sans-serif;
  border-bottom: 0; }
  #forum th a {
    color: #FFFFFF; }

#forum tr {
  height: 40px;
  background-color: #FFFFFF; }

#forum tr#forum-list-15, #forum tr#forum-list-14 {
  background-color: #DFFFD6;
  font-size: 1.3em; }
  #forum tr#forum-list-15 a, #forum tr#forum-list-14 a {
    color: #000000; }

#forum td.topics, #forum td.posts, #forum th, #forum td.replies {
  text-align: center; }

#forum td.last-reply, #forum td.title {
  padding-left: 20px; }

#forum-add-new-topic {
  margin-top: 20px; }

/** forum node **/
body.node-type-forum h1 {
  margin-bottom: 5px; }

body.node-type-forum .submitted {
  font-style: italic;
  color: #777; }

body.node-type-forum .node-forum > .content {
  padding-left: 0 !important;
  padding-right: 0 !important; }

body.node-type-forum .field-name-body {
  margin: 30px 0 40px 0;
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  padding: 30px; }

body.node-type-forum .indented {
  border-left: 15px solid #3b698d;
  margin-left: 0;
  padding-left: 10px; }

body.node-type-forum .comment {
  border-left: 15px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  padding: 20px; }
  body.node-type-forum .comment h3 {
    margin-top: 0;
    margin-bottom: 0; }
    body.node-type-forum .comment h3 a, body.node-type-forum .comment h3 a:visited {
      color: #4f4b4c;
      font-weight: bold;
      border-bottom: 3px solid #874C00; }
  body.node-type-forum .comment .content {
    margin: 20px; }
  body.node-type-forum .comment ul.inline.links {
    padding: 5px 10px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#feccb1+0,f17432+83,f17432+83,ea5507+84,fb955e+100 */
    background: #feccb1;
    /* Old browsers */
    background: -moz-linear-gradient(top, #feccb1 0%, #f17432 83%, #f17432 83%, #ea5507 84%, #fb955e 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #feccb1 0%, #f17432 83%, #f17432 83%, #ea5507 84%, #fb955e 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #feccb1 0%, #f17432 83%, #f17432 83%, #ea5507 84%, #fb955e 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feccb1', endColorstr='#fb955e',GradientType=0 );
    /* IE6-9 */
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px; }
    body.node-type-forum .comment ul.inline.links a {
      color: #4f4b4c; }
  body.node-type-forum .comment span.new {
    background-color: #5593d1;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px; }

body.node-type-forum #comment-container {
  background-color: #f0f1f4;
  border-top: 1px solid #3b698d;
  border-bottom: 1px solid #3b698d;
  color: #1D2731;
  background-color: #DFFFD6;
  padding: 20px;
  margin-top: 40px; }

.page-support #main-wrapper {
  background-color: white; }
  .page-support #main-wrapper table td {
    color: #1D2731; }
  .page-support #main-wrapper, .page-support #main-wrapper p,
  .page-support #main-wrapper ol, .page-support #main-wrapper ul, .page-support #main-wrapper li, .page-support #main-wrapper h1, .page-support #main-wrapper h2, .page-support #main-wrapper h3, .page-support #main-wrapper h4, .page-support #main-wrapper h5, .page-support #main-wrapper h6 {
    color: #1D2731; }
  .page-support #main-wrapper h1 {
    border-bottom: 1px solid #1D2731; }
  .page-support #main-wrapper #right a:link {
    color: #d99510;
    text-decoration: none; }
  .page-support #main-wrapper #right a:visited {
    color: #cc4d00;
    text-decoration: none; }
  .page-support #main-wrapper #right a:hover {
    color: #D9B310;
    text-decoration: none; }

.node-support {
  /* html5: The <i> tag can be used to indicate a technical term. */
  /* html5: The <kbd> tag is a phrase tag. It defines keyboard input. */ }
  .node-support h4 {
    margin-top: 20px; }
  .node-support ul {
    list-style: disc; }
  .node-support ol {
    list-style-type: decimal; }
  .node-support div#oops {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 2em;
    font-size: x-large;
    color: darkorange; }
  .node-support ul.nav {
    clear: both;
    margin: .5em;
    padding: 0px .5em; }
  .node-support div#header ul.nav {
    margin-top: -1.5em; }
  .node-support div#footer ul.nav {
    margin-bottom: 1.5em; }
  .node-support li.nav1, .node-support li.navn {
    display: inline;
    padding: 0px .5em; }
  .node-support a:hover, .node-support a:active {
    text-decoration: underline; }
  .node-support span.guimenuitem {
    background-color: #e1e3e9;
    padding-left: 5px;
    padding-right: 5px;
    color: #1D2731;
    font-weight: bold;
    font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", sans-serif; }
  .node-support span.pathname {
    font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", sans-serif; }
  .node-support span.keycommand {
    font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva", "Verdana", sans-serif;
    font-weight: bold; }
  .node-support span.guibutton {
    font-family: "Arial Unicode MS", "Arial Unicode", "Arial" , sans-serif; }
  .node-support th {
    border-bottom: none;
    text-align: center; }
  .node-support tr.i, .node-support td.i {
    font-style: italic; }
  .node-support th.l {
    text-align: left; }
  .node-support kbd, .node-support span.kbd, .node-support tr.kbd, .node-support td.kbd {
    font-family: monospace;
    font-size: larger;
    background-color: #61686f;
    color: #f0f1f4;
    border-radius: none;
    box-shadow: none; }
  .node-support span.underline {
    text-decoration: underline; }
  .node-support table.typein th, .node-support table.typein td {
    border: 1px solid #1D2731;
    padding: 5px 10px;
    text-align: center; }
  .node-support table.typein th {
    color: #f0f1f4;
    background-color: #2d7dad; }
  .node-support table.typein tr:nth-child(odd) {
    background-color: #f0f1f4; }
  .node-support table.typein tr:nth-child(even) {
    background-color: #e1e3e9; }
  .node-support table th, .node-support table td {
    padding-left: 5px;
    text-align: left; }
  .node-support blockquote {
    border-left: none; }
  .node-support dd {
    margin-left: 2.5em; }

#support-main-row {
  margin: 0;
  color: #1D2731; }
  #support-main-row #left {
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    border: 5px solid #f0f1f4;
    background-color: #61686f;
    padding-bottom: 30px;
    padding-right: 20px;
    margin-top: 8px;
    position: relative; }
    #support-main-row #left h3 {
      color: #f0f1f4;
      text-align: center;
      border-bottom: 2px solid #f0f1f4;
      margin-bottom: 20px;
      padding-bottom: 10px; }
    #support-main-row #left > .term-item {
      border: none;
      display: block; }
  #support-main-row .term-item {
    border-left: 2px solid #0A3858;
    padding-left: 15px;
    display: none;
    padding-bottom: 10px; }
    #support-main-row .term-item.expanded > .term-item,
    #support-main-row .term-item.expanded > .list-of-articles {
      display: block; }
    #support-main-row .term-item.active > .term-name {
      background-color: #0A3858;
      padding-left: 5px;
      color: #FFFFFF; }
      #support-main-row .term-item.active > .term-name a {
        color: white; }
  #support-main-row .term-name {
    font-size: 1.1em;
    cursor: pointer; }
    #support-main-row .term-name a {
      color: #eed; }
  #support-main-row .expand-icon {
    height: 15px;
    width: 15px;
    margin-right: 10px; }
  #support-main-row .article-item a {
    color: #f0f1f4; }
    #support-main-row .article-item a:hover {
      text-decoration: none; }
  #support-main-row .article-item.active {
    margin-left: -15px;
    padding-left: 15px;
    margin-right: -5px;
    padding-right: 5px;
    background-color: #0A3858; }
    #support-main-row .article-item.active a,
    #support-main-row .article-item.active .fa-file-text-o {
      color: #FFFFFF; }
  #support-main-row .list-of-articles {
    border-left: 2px solid #0A3858;
    padding-left: 15px;
    display: none; }
  #support-main-row .text-icon {
    float: left; }
  #support-main-row .text-container {
    margin-left: 25px; }
  #support-main-row .fa-file-text-o {
    margin-right: 10px; }
  #support-main-row #related-articles {
    margin-top: 80px;
    border-top: 3px solid gray; }

body.node-type-content-gallery .field-name-field-sample-content-images .field-item {
  float: left; }
  body.node-type-content-gallery .field-name-field-sample-content-images .field-item img {
    height: 200px !important;
    width: 155px !important; }

#content-galleries {
  background-color: #0A3858;
  border: 3px solid #d99510;
  padding-bottom: 20px; }
  #content-galleries span.field-content {
    font-size: 1.4em; }
  #content-galleries h4 {
    font-weight: 100; }

.disclosure-triangle {
  display: inline;
  vertical-align: middle; }
  .disclosure-triangle.discussion {
    color: #1D2731; }

.discussion-listing-doc-wrapper, .discussion-listing-doc-comment-wrapper {
  opacity: 0;
  max-height: 0;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  overflow: hidden; }

.discussion-listing-doc-wrapper.expanded, .discussion-listing-doc-comment-wrapper.expanded {
  opacity: 1;
  max-height: unset; }

.discussion-listing-doc-wrapper {
  border-left: 3px solid #666;
  padding-left: 10px; }

span.set-new-comment-label {
  background-color: #0d4700;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px;
  margin-left: 10px;
  border-radius: 3px; }

span.doc-new-comment-label {
  background-color: #0d4700;
  color: #fff;
  font-size: 11px;
  padding: 2px 4px;
  margin-left: 10px;
  border-radius: 2px; }

.discussion-listing-set {
  padding: 5px 20px;
  text-decoration: none; }
  .discussion-listing-set h2 {
    margin: 0; }
    .discussion-listing-set h2 a, .discussion-listing-set h2 a:visited, .discussion-listing-set h2 a:hover, .discussion-listing-set h2 a:active {
      color: #1D2731;
      text-decoration: none; }

.discussion-listing-doc h4 {
  margin: 4px 0; }
  .discussion-listing-doc h4 a, .discussion-listing-doc h4 a:visited, .discussion-listing-doc h4 a:hover, .discussion-listing-doc h4 a:active {
    text-decoration: none;
    color: #2d7dad; }

.comment-listing-entry {
  background-color: #072941;
  padding: 5px 10px;
  margin-bottom: 4px;
  color: #f0f1f4; }

.comment-listing-entry.is-new {
  background-color: #470e0e; }

span.is-new-label {
  background-color: #fff;
  color: #000;
  padding: 4px;
  border-radius: 3px;
  font-size: 11px;
  margin-right: 10px; }

#harmonia-comment-container textarea {
  width: 100%;
  height: 200px;
  border-radius: unset; }

#harmonia-comment-container object {
  height: 1000px;
  width: 100%; }

.comment-entry {
  margin: 5px 0px 5px 0px;
  padding: 5px 10px;
  background-color: #eed;
  color: #000; }

.comment-entry.current-user {
  background-color: #b9d9ed; }

.comment-time {
  margin-top: 5px;
  text-align: right; }

#comment-object-wrapper {
  background: #fff url("/sites/all/themes/harmonia/images/loading_spinner.gif") center center no-repeat; }

button.discussion-submit {
  margin-left: 0px; }
